import React from 'react'
import { urls } from '../../config'
import styles from './footer.module.css'
import { SocialMediaFooter } from 'components/SocialMedia/'

export default props => (
  <footer className={styles.footer}>
    <div
      className={props.theme === 0
        ? styles.titleWhite
        : styles.titlePink}>
      <h2>{props.currentSite}</h2>
    </div>
    <div className={styles.footerContain}>
      <div className={styles.leftSide}>
        <div className={styles.linksWebsites}>
          <a
            href={'https://location-palombaggia.com'}
          >
            Palombaggia
          </a>
          <a
            href={'https://villas-rossa.com'}
            className={styles.active}
          >
            Cala Rossa
          </a>
        </div>
        <div className={styles.lastLine}></div>
      </div>
      <div className={styles.rightSide}>
        <a
          href={'https://comdeslezards.com'}
          rel="nofollow"
          style={{
            textDecoration: 'none',
            color: 'initial'
          }}>
          <h5>©COM DES LEZARDS, 2018</h5>
        </a>
        <SocialMediaFooter lang={props.lang}/>
      </div>
    </div>
  </footer>
)

export const FooterPreviousVersion = props => <footer className={styles.footer}>
  <div
    className={props.theme === 0
      ? styles.titleWhite
      : styles.titlePink}>
    <h2>Villa Rossa</h2>
  </div>
  <div className={styles.footerContain}>
    <div className={styles.leftSide}>
      <div className={styles.linksWebsites}>
        <a href={props.baseUrl + urls.palombagia}>Palombagia</a>
        <a href={props.baseUrl + urls.marinarossa} className={styles.active}>Cala Rossa</a>
      </div>
      <div className={styles.lastLine}>
        <h5>©COM DES LEZARDS, 2018</h5>
        <SocialMediaFooter lang={props.lang}/>
      </div>
    </div>
    <div className={styles.rightSide}>
      <div className={styles.linkContain}>
        Villa Rossa - Palombagia MarinaRossa - Copyright
      </div>
    </div>
  </div>
</footer>
