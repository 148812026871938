const SLUGS = {
  index: {
    title_fr: 'Accueil',
    title_en: 'Home',
    title_it: 'Casa Affitto',
    title_de: 'Ferienhaus',
    fr: '/',
    en: 'rental-villas-south-corsica',
    it: 'affitto-corsica-del-sud',
    de: 'ferienhaus-sudkorsika'
  },
  situation: {
    title_fr: 'Situation',
    title_en: 'Location',
    title_it: 'Posizione',
    title_de: 'Lage',
    fr: 'location-porto-vecchio/situation.php',
    en: 'rental-villas-south-corsica/location.php',
    it: 'affitto-corsica-del-sud/posizione.php',
    de: 'ferienhaus-sudkorsika/lage.php',
  },
  villas: {
    title_fr: 'Villas',
    title_en: 'Villas',
    title_it: 'Ville',
    title_de: 'Villen',
    fr: 'location-porto-vecchio/villas.php',
    en: 'rental-villas-south-corsica/villas.php',
    it: 'affitto-corsica-del-sud/ville.php',
    de: 'ferienhaus-sudkorsika/villen.php'
  },
  piscine: {
    title_fr: 'Piscine',
    title_en: 'Swimming-Pool',
    title_it: 'Piscina',
    title_de: 'Pool',
    fr: 'location-porto-vecchio/piscine.php',
    en: 'rental-villas-south-corsica/swimming-pool.php',
    it: 'affitto-corsica-del-sud/piscina.php',
    de: 'ferienhaus-sudkorsika/pool.php',
  },
  tarifs: {
    title_fr: 'Tarifs',
    title_en: 'Rates',
    title_it: 'Tariffa',
    title_de: 'Tarife',
    fr: 'location-porto-vecchio/tarifs.php',
    en: 'rental-villas-south-corsica/rates.php',
    it: 'affitto-corsica-del-sud/tariffa.php',
    de: 'ferienhaus-sudkorsika/tarife.php',
  },
  photos: {
    title_fr: 'Photos',
    title_en: 'Pics',
    title_it: 'Le Foto',
    title_de: 'Bilder',
    fr: 'location-porto-vecchio/photos.php',
    en: 'rental-villas-south-corsica/pics.php',
    it: 'affitto-corsica-del-sud/le-foto.php',
    de: 'ferienhaus-sudkorsika/bilder.php',
  },
  contact: {
    title_fr: 'Contact',
    title_en: 'Contact',
    title_it: 'Contatto',
    title_de: 'Kontakt',
    fr: 'location-porto-vecchio/contact.php',
    en: 'rental-villas-south-corsica/contact.php',
    it: 'affitto-corsica-del-sud/contatto.php',
    de: 'ferienhaus-sudkorsika/kontakt.php',
  },
}

function getPageInfos(page, lang, type) {
  const pageInfos = SLUGS[page];
  let valueName = type === 'title' ? `title_${lang.toLowerCase()}` : lang.toLowerCase();
  return pageInfos[valueName];
}

function getPageSlug(page, lang) {
  return getPageInfos(page, lang, 'slug');
}

function getPageTitle(page, lang) {
  return getPageInfos(page, lang, 'title');
}

exports.getPageSlug = getPageSlug
exports.getPageTitle = getPageTitle
exports.SLUGS = SLUGS