import React, { Component } from 'react'
import { Link } from 'gatsby'
import { internalUrls } from 'config'
import { LinkDisplay, getUrlLang } from 'utils'
import { getPageSlug } from '../../slugs'
import Menu from 'react-burger-menu/lib/menus/slide'
import { SocialMediaHeader } from 'components/SocialMedia/'
import styles from './header.module.css'

class Header extends Component {
  constructor (props) {
    super()

    this.state = {
      isScroll: false
    }

    this.handleScroll = this
      .handleScroll
      .bind(this)
  }
  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }
  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }
  handleScroll () {
    if (this.props.scroll) {
      if (window.scrollY >= parseInt(this.props.scroll) && !this.state.isScroll) {
        this.setState({ isScroll: true })
      } else if (window.scrollY < parseInt(this.props.scroll) && this.state.isScroll) {
        this.setState({ isScroll: false })
      }
    }
  }
  render () {
    return (
      <header style={{
        zIndex: 50
      }}>
        <Menu
          right
          burgerButtonClassName={styles.linkContainResponsive + (!this.state.isScroll && this.props.scroll !== 0
            ? ''
            : ' menu_fixed')}
          pageWrapId={'page-wrap'}
          outerContainerId={'outer-container'}>
          <LinkDisplay
            lang={this.props.lang}
            slugs={this.props.slugs}
            links={getUrlLang(internalUrls)}
            baseUrl={this.props.baseUrl}
            currentPage={this.props.currentPage}/>
          <SocialMediaHeader lang={this.props.lang}/>
        </Menu>
        <div
          className={!this.state.isScroll && this.props.scroll !== 0
            ? styles.header
            : styles.headerFix}>
          <div className={styles.headerContain}>
            <div className={styles.logoContain}>
              <Link to={getPageSlug('index', this.props.lang)}>
                <h3>{this.props.title}</h3>
                <h5>{this.props.subTitle}</h5>
              </Link>
            </div>
            <div className={styles.linkContain}>
              <LinkDisplay
                lang={this.props.lang}
                slugs={this.props.slugs}
                links={getUrlLang(internalUrls)}
                baseUrl={this.props.baseUrl}
                currentPage={this.props.currentPage}
                separator/>
            </div>

            <div className={styles.linkBurger}></div>
          </div>
        </div>
      </header>
    )
  }
}

export default Header
